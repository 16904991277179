import { styled } from "@linaria/react";
import {
  type KeyboardEvent,
  type StyledComponent,
  type FunctionComponent,
  useCallback,
  useRef,
  useState,
} from "react";

import { type ConversationDTO } from "~/dto/conversation";
import { type ProjectDTO } from "~/dto/project";
import { useConversationFromProject } from "~/hooks/useConversation";
import { useCurrentUser } from "~/hooks/useCurrentUser";
import { text } from "~/styles/typography";

import A from "../library/A";
import { ButtonKind, ButtonSize } from "../library/Button";
import Icon from "../library/Icon";
import IconButton from "../library/IconButton";
import { TooltipBody } from "../library/Tooltip";

export type ConversationTileProps = StyledComponent & {
  collapsed: boolean;
  conversation: ConversationDTO;
  index: number;
  isSelected: boolean;
  project: ProjectDTO;
};

const ConversationListItem = styled.li`
  border: 2px solid var(--border-color-secondary);
  border-radius: var(--border-radius-sm);
  position: relative;
  color: var(--text-color-primary);
  background-color: var(--background-color-primary);
  ${text.xs.medium};

  &[aria-current] {
    border: 2px solid var(--border-color-brand-solid);
  }

  [data-icon] {
    width: 16px;
    height: 16px;
  }

  a {
    padding: var(--spacing-lg);
    display: grid;
    grid-template-areas:
      "title status"
      "timestamp timestamp";
    grid-template-columns: 1fr max-content;
    gap: var(--spacing-md);
    color: var(--text-color-primary);
    outline-color: var(--text-color-primary);

    &:hover {
      text-decoration: none;
    }

    [slot="title"] {
      grid-area: title;
      ${text.xs.semibold};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    [data-has-tooltip],
    [data-has-tooltip]:focus {
      outline: none;
      text-align: start;
      /* react-aria-components tooltips have to have a focusable trigger element
       * for keyboard-nav purposes, but we want that button to act as much like
       * the parent <a> as possible */
      background: transparent;
      cursor: inherit;
    }

    [role="status"] {
      grid-area: status;
      display: flex;
      align-items: center;
      grid-gap: var(--spacing-md);
      justify-content: flex-end;

      &[data-status="RUNNING"],
      &[data-status="PENDING"] {
        color: var(--text-color-disabled);
      }
      &[data-status="FAILED"] {
        color: var(--text-color-error-primary);
      }
    }

    time {
      grid-area: timestamp;
      justify-self: end;
      width: 67%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  [slot="actions"] {
    /* can't nest buttons inside of hyperlinks so we absolutely position this */
    position: absolute;
    /* don't forget to take into account any button padding */
    left: var(--spacing-sm);
    bottom: var(--spacing-xs);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: var(--spacing-xs);
  }

  &[data-collapsed="true"] {
    a {
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      [role="status"],
      time {
        display: none;
      }

      [role="title"] {
        text-align: center;
        align-self: stretch;
      }
    }

    [slot="actions"] {
      display: none;
    }
  }
`;

type ConversationStatusProps = StyledComponent & {
  status: ConversationDTO["status"];
};
const ConversationStatus: FunctionComponent<ConversationStatusProps> = (
  props,
) => {
  const { status, ...otherProps } = props;

  let statusText: string, iconName: UntitledIcon;

  switch (status) {
    case "SUCCESS": {
      statusText = "View";
      iconName = "arrow-right";
      break;
    }
    case "RUNNING": {
      statusText = "Running";
      iconName = "hourglass-01";
      break;
    }
    case "FAILED": {
      statusText = "Failed";
      iconName = "alert-circle";
      break;
    }
    case "PENDING":
    default: {
      statusText = "Pending";
      iconName = "hourglass-01";
    }
  }

  return (
    <div
      data-status={status}
      /* implies aria-live=polite, which is great */
      role="status"
      {...otherProps}
    >
      {statusText}
      <Icon family="untitled" name={iconName} />
    </div>
  );
};

const useConversationTile = (props: ConversationTileProps) => {
  const { collapsed, conversation, index, isSelected, project, ...otherProps } =
    props;
  const { refresh, rerun } = useConversationFromProject(conversation.id, {
    projectId: project.id,
  });
  const { hasPermission } = useCurrentUser();
  const tooltipTriggerRef = useRef(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const ranAt = conversation.last_run
    ? new Date(conversation.last_run).toLocaleDateString("en-us", {
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        month: "short",
        year: "numeric",
      })
    : "";
  const createdAt = conversation.created_at
    ? new Date(conversation.created_at).toLocaleDateString("en-us", {
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        month: "short",
        year: "numeric",
      })
    : "";
  const onRerunButtonClick = useCallback(async () => {
    setIsUpdating(true);
    try {
      await rerun(conversation);
      await refresh();
    } catch {
      // do nothing
    } finally {
      setIsUpdating(false);
    }
  }, [conversation, rerun, refresh]);
  const onMouseEnter = useCallback(() => {
    setShowTooltip(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);
  /** @see https://www.w3.org/WAI/content-assets/wai-aria-practices/patterns/button/examples/js/button.js */
  const onTriggerKeyDown = useCallback((e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.code.toLowerCase() === "space") {
      e.preventDefault();
      e.stopPropagation();
    } else if (e.code.toLowerCase() === "enter") {
      e.preventDefault();
      e.stopPropagation();
      setShowTooltip((prev) => !prev);
    }
  }, []);
  const onTriggerKeyUp = useCallback((e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.code.toLowerCase() === "space") {
      e.preventDefault();
      e.stopPropagation();
      setShowTooltip((prev) => !prev);
    }
  }, []);
  const rerunDisabled =
    isUpdating || ["PENDING", "RUNNING"].includes(conversation.status);
  const showRerunButton =
    (!conversation.threat_feed || hasPermission("RERUN_SCHEDULED_QUERY")) &&
    !conversation.is_scaled &&
    !conversation.is_nrms;

  return {
    collapsed,
    conversation,
    createdAt,
    index,
    isSelected,
    onItemMouseEnter: collapsed ? onMouseEnter : undefined,
    onItemMouseLeave: collapsed ? onMouseLeave : undefined,
    onTriggerKeyDown,
    onTriggerKeyUp,
    onTriggerMouseEnter: collapsed ? undefined : onMouseEnter,
    onTriggerMouseLeave: collapsed ? undefined : onMouseLeave,
    onRerunButtonClick,
    otherProps,
    project,
    ranAt,
    rerunDisabled,
    showRerunButton,
    showTooltip,
    status: isUpdating ? "PENDING" : conversation.status,
    tooltipTriggerRef,
  };
};

const ConversationTile: FunctionComponent<ConversationTileProps> = (props) => {
  const {
    collapsed,
    conversation,
    index,
    isSelected,
    onItemMouseEnter,
    onItemMouseLeave,
    onTriggerKeyDown,
    onTriggerKeyUp,
    onTriggerMouseEnter,
    onTriggerMouseLeave,
    onRerunButtonClick,
    otherProps,
    project,
    ranAt,
    rerunDisabled,
    status,
    showRerunButton,
    showTooltip,
    tooltipTriggerRef,
  } = useConversationTile(props);
  const elId = `single-conv-${conversation.id}`;
  const current = isSelected ? "page" : undefined;

  return (
    <ConversationListItem
      ref={tooltipTriggerRef}
      aria-current={current}
      data-collapsed={collapsed}
      onMouseEnter={onItemMouseEnter}
      onMouseLeave={onItemMouseLeave}
      {...otherProps}
    >
      <A
        aria-labelledby={elId}
        data-conversation={conversation.id}
        href={`/projects/${encodeURIComponent(project.name)}/${
          conversation.id
        }`}
      >
        <ConversationStatus status={status} />
        <span slot="title">
          <span
            ref={tooltipTriggerRef}
            onKeyDownCapture={onTriggerKeyDown}
            onKeyUpCapture={onTriggerKeyUp}
            onMouseEnter={onTriggerMouseEnter}
            onMouseLeave={onTriggerMouseLeave}
            role="button"
            tabIndex={0}
          >
            {collapsed ? String(1 + index) : conversation.name}
          </span>
        </span>
        <time dateTime={conversation.last_run}>{ranAt}</time>
      </A>
      <div slot="actions">
        {showRerunButton && (
          <IconButton
            aria-label="Re-run conversation"
            data-kind={ButtonKind.Tertiary}
            data-size={ButtonSize.sm}
            disabled={rerunDisabled}
            icon="refresh-ccw-01"
            onPress={onRerunButtonClick}
            type="button"
          />
        )}
        {conversation.threat_feed && (
          <Icon
            aria-label={
              conversation.suspended
                ? "Suspended Scheduled Query"
                : "Scheduled query"
            }
            family="untitled"
            name={conversation.suspended ? "pause-circle" : "clock-stopwatch"}
          />
        )}
      </div>

      {showTooltip && (
        <TooltipBody
          contents={conversation.name}
          triggerRef={tooltipTriggerRef}
        />
      )}
    </ConversationListItem>
  );
};
export default ConversationTile;
