import { styled } from "@linaria/react";
import { AxisBottom, type TickRendererProps } from "@visx/axis";
import { Group } from "@visx/group";
import ParentSize from "@visx/responsive/lib/components/ParentSizeModern";
import { scaleBand } from "@visx/scale";
import { Bar } from "@visx/shape";
import { Tooltip as VxTooltip, useTooltip } from "@visx/tooltip";
import {
  useCallback,
  useMemo,
  type FunctionComponent,
  type MouseEventHandler,
  type ReactNode,
} from "react";

import type { NrmsConversationThemeMetricsDTO } from "~/dto/nrmsConversationThemeMetrics";
import { text } from "~/styles/typography";
import { formatDate } from "~/utils/datetime";

import Tooltip, { TooltipPosition } from "../charts/Tooltip";
import { getSpectrumPalette } from "../charts/utils";

import { EvergreenThemeLabels } from "./constants";

type NarrativeVolumeBarChartProps = Pick<
  NrmsConversationThemeMetricsDTO,
  "theme_name" | "volume_history"
>;

interface ResponsiveNarrativeVolumeBarChartProps
  extends Pick<
    NrmsConversationThemeMetricsDTO,
    "theme_name" | "volume_history"
  > {
  width: number;
  height: number;
}

const BarChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const BarChartSVG = styled.svg`
  overflow: visible;
`;

const AxisBottomTick = styled.text`
  ${text.xs.regular};
`;

const BarGroup = styled.g`
  &:hover,
  &:focus {
    filter: brightness(90%);
  }
`;

const HEIGHT_OFFSET = 10;
const MAX_HEIGHT = 100;

function useNarrativeVolumeBarChart({
  theme_name,
  volume_history,
  width,
}: ResponsiveNarrativeVolumeBarChartProps) {
  const {
    hideTooltip,
    showTooltip,
    tooltipData,
    tooltipLeft,
    tooltipOpen,
    tooltipTop,
  } = useTooltip<ReactNode>();

  // Filter out the latest 7 data items with both valid date and volume values.
  const volumeHistory = useMemo(() => {
    return volume_history
      ? volume_history
          .filter(({ date, volume }) => date && typeof volume === "number")
          .slice(-7)
      : [];
  }, [volume_history]);

  // Determine the x and y scales.
  const xScale = useMemo(() => {
    const getVolumeDates = (): string[] =>
      volumeHistory.map(({ date = "" }) => formatDate(new Date(date)) ?? date);

    return scaleBand<string>({
      domain: getVolumeDates(),
      padding: 0.2,
      range: [0, width],
      round: true,
    });
  }, [volumeHistory, width]);

  // Determine graph bar attributes.
  const getVolumeHistory = (): number[] =>
    volumeHistory.map(({ volume = 0 }) => volume);
  const maxVolume = Math.max(...getVolumeHistory()) + HEIGHT_OFFSET;

  const spectrumPalette = getSpectrumPalette(volumeHistory.length).reverse();
  const barColorPalette: { [key: number]: string } = {};

  const volumeHistoryData = volumeHistory.map(
    ({ date = "", volume = 0 }, i) => {
      // Ensure that data items with the same volume have the same bar color.
      // Map each unique volume to a specific bar color.
      if (!barColorPalette[volume]) {
        barColorPalette[volume] = spectrumPalette[i];
      }

      const formattedDate = formatDate(new Date(date));

      return {
        barColor: barColorPalette[volume],
        barHeight: (volume / maxVolume) * 100 + HEIGHT_OFFSET,
        barWidth: xScale.bandwidth(),
        barX: xScale(String(formattedDate)) ?? 0,
        barY: (MAX_HEIGHT + HEIGHT_OFFSET) / 2,
        date: formattedDate,
        label: `The volume for the ${EvergreenThemeLabels[theme_name]} topic was ${volume} on ${formattedDate}.`,
        volume,
      };
    },
  );

  // Handle hiding the bar graph tooltip.
  const onHideTooltip: MouseEventHandler<SVGRectElement> = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  // Handle showing the bar graph tooltip.
  const onShowTooltip: MouseEventHandler<SVGRectElement> = useCallback(
    (e) => {
      const target = e.currentTarget;

      showTooltip({
        tooltipData: target.getAttribute("aria-label"),
        tooltipLeft: target.getAttribute("data-xpos")
          ? Number(target.getAttribute("data-xpos"))
          : 0,
        tooltipTop: target.getAttribute("data-ypos")
          ? Number(target.getAttribute("data-ypos"))
          : 0,
      });
    },
    [showTooltip],
  );

  return {
    data: volumeHistoryData,
    maxBarHeight: (MAX_HEIGHT + HEIGHT_OFFSET) * 2,
    onHideTooltip,
    onShowTooltip,
    tooltipData,
    tooltipLeft,
    tooltipOpen,
    tooltipTop,
    xScale,
    zeroYPosition: MAX_HEIGHT + HEIGHT_OFFSET / 2,
  };
}

const renderAxisBottomTick: FunctionComponent<TickRendererProps> = ({
  formattedValue,
  ...rest
}) => {
  return <AxisBottomTick {...rest}>{formattedValue}</AxisBottomTick>;
};

const ResponsiveNarrativeVolumeBarChart: FunctionComponent<
  ResponsiveNarrativeVolumeBarChartProps
> = (props) => {
  const {
    data,
    maxBarHeight,
    onHideTooltip,
    onShowTooltip,
    tooltipData,
    tooltipLeft,
    tooltipOpen,
    tooltipTop,
    xScale,
    zeroYPosition,
  } = useNarrativeVolumeBarChart(props);

  return (
    <BarChartWrapper>
      <BarChartSVG aria-hidden="true" height={maxBarHeight} width={props.width}>
        <line
          stroke="var(--foreground-color-quinary)"
          strokeDasharray="1, 5"
          strokeWidth={1}
          transform={`translate(0, ${zeroYPosition})`}
          x1="0"
          x2={props.width}
          y1="5"
          y2="5"
        ></line>
        <Group top={data[0].barY}>
          {data.map(
            ({
              barColor,
              barHeight,
              barWidth,
              barX,
              barY,
              date,
              label,
              volume,
            }) => (
              <BarGroup
                key={`bar-group_${date}-${volume}`}
                aria-label={label}
                data-xpos={barX + barWidth}
                data-ypos={barHeight / 2}
                onMouseLeave={onHideTooltip}
                onMouseMove={onShowTooltip}
              >
                <Bar
                  key={`bar_${date}-${volume}--inversed`}
                  fill={barColor}
                  height={barHeight}
                  transform={`translate(0, -${barHeight})`}
                  width={barWidth}
                  x={barX}
                  y={barY}
                />
                <Bar
                  key={`bar_${date}-${volume}`}
                  fill={barColor}
                  height={barHeight}
                  width={barWidth}
                  x={barX}
                  y={barY}
                />
              </BarGroup>
            ),
          )}
        </Group>
        <AxisBottom
          hideAxisLine
          hideTicks
          numTicks={Math.floor(data.length / 3)}
          scale={xScale}
          tickComponent={renderAxisBottomTick}
          top={maxBarHeight}
        />
      </BarChartSVG>
      {tooltipOpen && tooltipData && (
        <VxTooltip
          applyPositionStyle
          left={tooltipLeft}
          top={tooltipTop}
          unstyled
        >
          <Tooltip data-position={TooltipPosition.Right}>{tooltipData}</Tooltip>
        </VxTooltip>
      )}
      <table data-at-only>
        <thead>
          <tr>
            <td>Date</td>
            <td>Narrative Volume</td>
          </tr>
        </thead>
        <tbody>
          {data.map(({ date, volume }) => (
            <tr key={`table-data_${date}-${volume}`}>
              <td>{date}</td>
              <td>{volume}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </BarChartWrapper>
  );
};

const NarrativeVolumeBarChart: FunctionComponent<
  NarrativeVolumeBarChartProps
> = (props) => {
  if (!props.volume_history) return null;

  return (
    <ParentSize>
      {({ width, height }) => (
        <ResponsiveNarrativeVolumeBarChart
          height={height}
          width={width}
          {...props}
        />
      )}
    </ParentSize>
  );
};

export default NarrativeVolumeBarChart;
